.wrapper {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--light-bg);
}

.boxWrapper {
    width: 400px;
    height: 340px;
    background: white;
    border: 1px solid #d8dbe0;
    border-radius: 0.25rem;
    padding: 20px;
}

.logoSection {
    text-align: center;
}

.mainSection {

}

.inputBox {
    margin-bottom: 15px;
}

.btnWrapper {
    display: flex;
    flex-direction: column;
}

.loginBtn {
    height: 40px;
    margin-bottom: 5px;
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}

.spinner {
    display: inline-block;
    animation: spin 2s linear infinite;
}

