.wrapper {
    display: flex;
    position: relative;
}

.loadingIndicator {
    position: fixed;
    bottom: 15px;
    right: 15px;

    width: 50px;
    height: 50px;
    border-radius: 50%;

    box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.1);
    background-color: white;

    display: flex;
    align-items: center;
    justify-content: center;
}

@keyframes loading {
    0% {
        opacity: 1.0;
        transform: rotate(0);
    }
    50% {
        opacity: 0.6;
        transform: rotate(90deg);
    }
    100% {
        opacity: 1.0;
        transform: rotate(180deg);
    }
}

.loadingIndicator > i:before {
    font-size: 40px;
    color: #414141;
    animation: loading 1.5s infinite linear;
}

.rightSection {
    flex: 1;
}

.mainContentWrapper {
    height: calc(100vh - 56px);
    overflow-y: auto;
    overflow-x: hidden;
    padding: 10px;
    background-color: var(--light-bg);
}