.buttonWrapper {
    margin-bottom: 10px;
}

.buttonWrapper > button:first-of-type {
    margin-right: 10px;
}

.unSaved {
    color: red;
    font-style: italic;
    margin-left: 10px;
}

.selectWrapper {
    margin-bottom: 20px;
}

.mustSelectClass {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
}