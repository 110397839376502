.page__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.page__title > h1 {
    margin-bottom: 0;
}
