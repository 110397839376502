.wrapper {
    height: 56px;
    width: 100%;
    border-bottom: 1px solid #d8dbe0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.wrapper > span, .wrapper > button {
    margin-right: 10px;
}