.wrapper {
    width: 256px;
    background-color: var(--blue-main);
    height: 100vh;
}

.logoWrapper {
    height: 56px;
    background-color: var(--blue-dark);
    display: flex;
    justify-content: center;
    align-items: center;
}

.logoWrapper > h3 {
    color: white;
    margin: 0 0 0 10px;
    font-size: 20px;
    font-weight: 300;
}

.btnList {
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
}

.menuBtn {
    color: hsla(0, 0%, 100%, .8);
    flex: 1;
    text-align: start;
    font-size: 16px;
    font-weight: 400;
    padding: .8445rem 1rem;
    transition: background .3s, color .3s;
}

.menuBtn > a > i {
    padding-right: 20px;
}

.menuBtn:hover, .menuBtnFocused {
    color: #fff;
    background: var(--orange-main);
}