.header {
  display: flex;
  justify-content: space-between;
}

.imageGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;

  .imgWrapper {
    display: flex;
    flex-direction: column;

    img {
      width: 100%;
    }

    button {
      width: 50%;
      margin: 10px auto;
    }
  }
}